// event.selectors.ts

import { EventJoinerModel } from '@index/models/event-joiner';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';
import moment from 'moment-timezone';

import { ArkSearchBarFilter } from '../../../../../../ark/src/public-api';
import {
  selectEventsByCreatorId,
  selectEventsByLatLngForAuthUser,
  selectFilteredEvents,
} from '../events/selectors';
import { EventState } from '../events/state';
import { generateBoundsFromLatLng, isLocationWithinBounds } from '../helpers/geolocation';
import { JoinerState } from './state';

export const selectJoinerState = createFeatureSelector<EventState>('event');

export const selectJoinersByEventId = (eventId: string) =>
  createSelector(
    selectJoinerState,
    (state: JoinerState) => {
      return state.joiners[eventId];
    },
  );

export const selectAllJoiners =
  createSelector(
    selectJoinerState,
    (state: JoinerState) => state.joiners,
  );

export const selectJoinersWithEventsByLatLng = (
  lat: number,
  lng: number,
  limit = 100,
  filter?: ArkSearchBarFilter,
  bounds?: google.maps.LatLngBoundsLiteral,
) =>
  createSelector(
    selectAllJoiners,
    selectFilteredEvents(filter),
    (joiners, events):
    {
      eventsAndJoiners: { event: GthEventItemModel, joiners: EventJoinerModel[] }[],
      total: number,
    } => {
      bounds = bounds || generateBoundsFromLatLng(lat, lng, 200);

      const filteredEventsAndJoiners = events
        /** If the event ends before right now, filter it out */
        .filter((event) => {
          const now = Date.now();

          return (
            isLocationWithinBounds(
              bounds,
              event,
              () => event.location.lat,
              () => event.location.lng,
            ) && // Event within bounds
            event.dateEnd.getTime() > now && // Event hasn't ended
            !event.inPast && // Event is not past
            !event.cancelled && // Event is not cancelled
            !event.isPrivate // Event is not private
          );
        })
        .map((event) => ({ event, joiners: joiners[event.id] || [] }));

      const sortedAndSlicedEventsAndJoiners = filteredEventsAndJoiners
        .sort((a, b) => {
          return moment(a.event.dateStart).diff(moment(b.event.dateStart));
        }).slice(0, limit);

      return {
        eventsAndJoiners: sortedAndSlicedEventsAndJoiners,
        total: filteredEventsAndJoiners.length,
      };
    },
  );

export const selectJoinersMappedWithEvents = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,

) =>
  createSelector(
    selectAllJoiners,
    selectEventsByLatLngForAuthUser(filter),
    (joiners, events):
    {
      eventsAndJoiners: { event: GthEventItemModel, joiners: EventJoinerModel[] }[],
      total: number,
    } => {
      const filteredEventsAndJoiners = events
        /** If the event ends before right now, filter it out */
        .filter((event) => {
          const now = Date.now();
          return event.dateEnd.getTime() > now;
        })
        /** If the event is private, filter it out */
        .filter((event) => {
          return !event.isPrivate;
        }).map((event) => ({ event, joiners: joiners[event.id] || [] }));

      const sortedAndSlicedEventsAndJoiners = filteredEventsAndJoiners
        .sort((a, b) => {
          return moment(a.event.dateStart).diff(moment(b.event.dateStart));
        }).slice(start, end);

      return {
        eventsAndJoiners: sortedAndSlicedEventsAndJoiners,
        total: filteredEventsAndJoiners.length,
      };
    },
  );

export const selectJoinersMappedWithEventsByCreatorId = (creatorId: string) =>
  createSelector(
    selectAllJoiners,
    selectEventsByCreatorId(creatorId),
    (joiners, events) => {
      return events
        .filter((event) => {
          const now = Date.now();
          return event.dateEnd.getTime() > now;
        })
        .map((event) => ({ event, joiners: joiners[event.id] || [] }))
        .sort((a, b) => moment(a.event.dateStart).diff(moment(b.event.dateStart)))
        .slice(0, 5);
    },
  );

export const selectEventsByUids = (eventId: string[]) =>
  createSelector(
    selectJoinerState,
    (state: JoinerState) => eventId.map((id) => state.joiners[id]).filter((event) => !!event),
  );

